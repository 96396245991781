import '/src/vendor/css/bootstrap.min.css';
import './style-login.css';
import '/src/resouces/css/style.css';
import { renderPage, navigate ,request } from '/src/resouces/js/commonRequest.js';
import { auth } from '/src/resouces/js/requestMain.js';
import { Buffer } from 'buffer-es6';
global.Buffer = Buffer;


const login = async () => {
  document.title = `Login`;

  // Renderiza la página de login
  await renderPage('/pages/login.hbs', { container: { undefined } });

  // Declarar las interfaces de validación
  interface ValidationState {
    [key: string]: boolean;
  }

  interface Validation {
    state: ValidationState;
    subscribers: ((state: ValidationState) => void)[];
    updateState: (id: string, stateValid: boolean) => void;
    subscribe: (callbackFunction: (state: ValidationState) => void) => void;
  }

  const validation: Validation = {
    state: {},
    subscribers: [],
    updateState: function (id: string, stateValid: boolean) {
      this.state[id] = stateValid;
      this.subscribers.forEach((subscriber) => {
        subscriber(this.state);
      });
    },
    subscribe: function (callbackFunction: (state: ValidationState) => void) {
      this.subscribers.push(callbackFunction);
    },
  };

  // Obtener los elementos del DOM
  const nombrevalidacion = document.getElementById('nombrevalidacion') as HTMLElement;
  const passvalidacion = document.getElementById('passvalidacion') as HTMLElement;
  const formulariovalit = document.getElementById('formulariovalidacion') as any
  const nombreval = document.getElementById('nombre') as HTMLInputElement;
  const passval = document.getElementById('pass') as HTMLInputElement;

  validation.subscribe((state: ValidationState) => {
    if (state.nombre === true) {
      nombrevalidacion.style.display = "block";
      nombrevalidacion.innerText = "Usuario Validado";
      nombrevalidacion.style.color = "#fff";
      nombrevalidacion.style.backgroundColor = "darkgreen";
    } else if (state.nombre === false) {
      nombrevalidacion.style.color = "#fff";
      nombrevalidacion.style.backgroundColor = "rgb(194, 0, 0)";
    }
    if (state.pass === false) {
      passvalidacion.style.color = "#fff";
      passvalidacion.style.backgroundColor = "rgb(194, 0, 0)";
    }
  });

  // Evento para registro de cuenta
  document.getElementById('registroClick')?.addEventListener("click", () => {
    navigate('/registro');
  });

  // Evento para recuperación de cuenta
  document.getElementById('recovery')?.addEventListener("click", () => {
    navigate('/password/recover');
  });

  // Agrega el evento de escucha al formulario
  formulariovalit?.addEventListener('submit', async (e:Event) => {
    e.preventDefault();

    const nombreInput = document.getElementById('nombre') as HTMLInputElement;
    const passInput = document.getElementById('pass') as HTMLInputElement;
    const confirNom = nombreInput.value;
    const confirPass = passInput.value;

    try {
      // Verifica si ya existe una sesión activa
      const sessionToken = sessionStorage.getItem("session");
      
      // Si no hay sesión activa, procede con la autenticación
      const usuario = await auth('post', 'auth-session', {
        nombre: confirNom
      }).then((response: any) => {
        return { nombre: response[0]?.nombre };
      }).catch(() => {
        console.log("Usuario Incorrecto");
      });
    
      if (usuario && usuario.nombre === confirNom) {
        validation.updateState("nombre", true);
        nombreInput.classList.add('is-valid');
        sessionStorage.setItem("nombre", usuario.nombre);

        if (sessionToken) {
          navigate('/')
          alert('El usuario ya tiene una sesión activa.');
          // Si ya hay sesión, detener el flujo aquí
        return 
        }
      
        const sessionvalid = await auth('post', 'auth-valid', {
          nombre: usuario.nombre
        }).then((response: any) => {
          return {
            id: response[0]?.id,
            correo: response[0]?.correo
          };
        }).catch((Error) => {
          console.log(Error);
        }) as any;
    
        await auth('post', 'login/user', {
          correo: sessionvalid.correo,
          contrasena: confirPass
        }).then(() => {
          nombrevalidacion.remove();
          passvalidacion.remove();
          nombreInput.classList.remove('is-valid');
          passInput.classList.remove('is-valid');
    
          auth('put', `dt-admin/session/${sessionvalid.id}`, {
            nombre: usuario.nombre,
            contrasena:confirPass
          }).then((response:any) => {
            const sessiontoken = response.token;
            sessionStorage.setItem("session", sessiontoken);
            formulariovalit?.reset();
          });
    
          nombreInput.value = '';
          passInput.value = '';
          alert('Inicio de sesión correctamente.');
          navigate('/');
          window.location.reload();
        });
      }
    
       else {
        validation.updateState("nombre", false);
        passvalidacion.style.display = "none";
        nombrevalidacion.style.display = "block";
        nombrevalidacion.innerText = "Usuario incorrecto!";
        nombreInput.classList.remove('is-valid');
      }

      if (nombreInput.value.length === 0 && passInput.value.length === 0) {
        nombrevalidacion.innerText = "Campo usuario está vacío!";
        nombrevalidacion.style.display = "block";
        passvalidacion.innerText = "Campo contraseña está vacío!";
        passvalidacion.style.display = "block";
        passvalidacion.style.color = "#fff";
        passvalidacion.style.backgroundColor = "rgb(194, 0, 0)";
      } else if (nombreInput.value.length === 0) {
        nombrevalidacion.innerText = "Campo usuario está vacío!";
        passvalidacion.style.display = "none";
      } else if (passInput.value.length === 0) {
        passvalidacion.innerText = "Campo contraseña está vacío!";
      }

    } catch (error) {
      console.log('Error al validar usuario:', error);
      validation.updateState("pass", false);
      passvalidacion.style.display = "block";
      passvalidacion.innerText = "Contraseña incorrecta!";
      if (nombreInput.value.length === 0 && passInput.value.length === 0) {
        nombrevalidacion.innerText = "Campo usuario está vacío!";
        nombrevalidacion.style.display = "block";
        passvalidacion.innerText = "Campo contraseña está vacío!";
        passvalidacion.style.display = "block";
        passvalidacion.style.color = "#fff";
        passvalidacion.style.backgroundColor = "rgb(194, 0, 0)";
      } else if (nombreInput.value.length === 0) {
        nombrevalidacion.innerText = "Campo usuario está vacío!";
        passvalidacion.style.display = "none";
      } else if (passInput.value.length === 0) {
        passvalidacion.innerText = "Campo contraseña está vacío!";
      }
    }

  });
}

export default login;
