import '/src/vendor/css/bootstrap.min.css';
import './style-login.css';
import '/src/resouces/css/style.css';
import { renderPage, navigate, request } from '/src/resouces/js/commonRequest.js';
import * as jwt from 'jsonwebtoken';
import { Buffer } from 'buffer-es6';
import { auth } from '/src/resouces/js/requestMain.js';
global.Buffer = Buffer;
       
const regForm = async()=>{

          
            ///Asignamos el titulo de la pagina
            document.title = window.location.pathname.replace("/","")
            ////Fin asignacion pagina
            ///////////////////////////////////////////////////////////////////
            
            function popstateHandler() {
                  if (!confirm("¿Estás seguro que deseas salir?")) {
                      navigate('/registro'); // Cancelar el retroceso en el historial si el usuario cancela la acción
                      window.removeEventListener('popstate', popstateHandler);
                    } else {
                      //login();
                      navigate('/login');// antiguo login()
                      window.location.reload();
                      //{loadPage};
                       // Remover el listener del evento popstate
                  }
              
          }
          window.addEventListener('popstate', popstateHandler);
          
          //Carga el hbs
          await renderPage('/pages/registro.hbs', { container: {undefined} });
           
          ///////////////
          //Cerrar reguistro
          document.getElementById('cerrarReg')?.addEventListener('click',()=>{
            if (!confirm("¿Estás seguro que deseas salir?")) {
              navigate('/registro'); // Cancelar el retroceso en el historial si el usuario cancela la acción
            } else {
              //login();
              navigate('/login');// antiguo login()
              window.location.reload();
             // {loadPage};
               // Remover el listener del evento popstate
          }
          })
          ////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////////7
          interface ValidationState {
            [key: string]: boolean;
        }
          
        interface Validation {
            state: ValidationState;
            subscribers: ((state: ValidationState) => void)[];
            updateState: (id: string, stateValid: boolean) => void;
            subscribe: (callbackFunction: (state: ValidationState) => void) => void;
            isValid: () => boolean;
        }
        const validation: Validation = {
            state: {},
            subscribers: [],
        
            updateState: function (id: string, stateValid: boolean) {
                this.state[id] = stateValid;
                this.subscribers.forEach((subscriber) => {
                    subscriber(this.state);
                });
            },
        
            subscribe: function (callbackFunction: (state: ValidationState) => void) {
                this.subscribers.push(callbackFunction);
            },
        
            isValid: function () {
                for (const id in this.state) {
                    if (!this.state[id]) {
                        return false;
                    }
                }
                return true;
            }
        };
          
          ////////////////////////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////
          
            const nombrevalidacion = document.getElementById('nombrevalidacion') as HTMLElement
            const emailvalidacion = document.getElementById('emailvalidacion') as HTMLElement
            const passvalidacion = document.getElementById('passvalidacion') as HTMLElement
            const formulariovalit=document.getElementById('formulariovalidacion') as HTMLElement
            const nombreval = document.getElementById('nombre') as HTMLInputElement 
            const emailval = document.getElementById('email')as HTMLInputElement 
            const passval = document.getElementById('pass') as HTMLInputElement 
          /////////////////////////////////////////////////////////////
          /////icons-visibility
          
           const materialIcon = document.querySelector('i');
            materialIcon?.addEventListener('click',()=>{
          
            if(materialIcon.innerText==="visibility_on"){
              materialIcon.innerText="visibility_off"
            }
            else if(materialIcon.innerText==="visibility_off"){
              materialIcon.innerText="visibility_on"
            }
            if(passval.type ==="password"){
              passval.type="text"
            }
            else{
              passval.type="password"
            }
            });
          
          
          //Fin materia icon
          ////////////////////////////////////////////////////////////
          validation.subscribe((state: ValidationState) => {
             if(state.nombre===true){
              nombrevalidacion.style.display="block"
              nombrevalidacion.style.color="#fff"
              nombrevalidacion.style.backgroundColor="darkgreen"
             }
             else{
              nombrevalidacion.style.color="#fff"
              nombrevalidacion.style.backgroundColor="rgb(194, 0, 0)"
             }
          
             if(state.email===true){
              emailvalidacion.style.display="block"
              emailvalidacion.style.color="#fff"
              emailvalidacion.style.backgroundColor="darkgreen"
             }
             else{
              emailvalidacion.style.color="#fff"
              emailvalidacion.style.backgroundColor="rgb(194, 0, 0)"
             }
          
             if(state.pass===true){
              passvalidacion.style.display="block"
              passvalidacion.style.color="#fff"
              passvalidacion.style.backgroundColor="darkgreen"
             }
             else{
              passvalidacion.style.color="#fff"
              passvalidacion.style.backgroundColor="rgb(194, 0, 0)"
             }
           
          });
          
          
            formulariovalit?.addEventListener('submit',async (e)=>{
            e.preventDefault()
            /*Validadndo usuario */

              const valorNombre = nombreval.value.trim()
              const validNombre = valorNombre.length>=4
              const valorEmail = emailval.value.trim()
              const validEmail = /\S+@\S+\.\S+/.test(valorEmail);
              const passValor = passval.value.trim()
              const passValid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()-_+=]{6,}$/.test(passValor); 
              // Esta expresion es longitud de 6 almenos 1 caracter y numero
            /////////////////////////////////////////////////////////////////
             

              ////////////////////////////////////////////////////////////////
              if(validNombre){
                validation.updateState("nombre", true)
                nombrevalidacion.innerText="Usuario Validado"
                nombreval.classList.add('is-valid');
              }
              else{
                if(nombreval.value.length===0){
                  nombrevalidacion.innerText="Campo usuario esta vacio!"
                }
                else{
                nombrevalidacion.innerText="Campo minimo 4 de longitud!"
              }
              validation.updateState("nombre", false)
              nombrevalidacion.style.display="block"
              nombreval.classList.remove('is-valid');
            }
            ////////////////////////////////////////////////////////////////////
               if(validEmail){
                validation.updateState("email", true)
                emailvalidacion.innerText="E-mail Validado"
                emailval.classList.add('is-valid');
              }
              else{
              emailvalidacion.innerText="E-mail no valido!"
              validation.updateState("email", false)
              emailvalidacion.style.display="block"
              emailval.classList.remove('is-valid');
            }
            /////////////////////////////////////////////////////////////////////
              if(passValid){
                validation.updateState("pass", true)
                passvalidacion.innerText="Contraseña Validada"
                passval.classList.add('is-valid');
              }
              else{
          
                if(passval.value.length===0){
                  passvalidacion.innerText="Campo contraseña esta vacio!"
                }
                else{
                  passvalidacion.innerText="Contraseña minima 6 longitud, 1 numero!"
                }
               
  
                validation.updateState("pass", false)
                passvalidacion.style.display="block"
                passval.classList.remove('is-valid');
               
              } 

            //////////////////////////////////////////////////////////

              const usuarios =  await auth('post','auth-session',{
                nombre:valorNombre
               }).then(()=>{
                navigate('/')
                window.location.reload
                alert('El sistema de reguistro no esta habilitado')
               
               })
              .catch(() => {
                console.log("Usuario Incorrecto");
              }) as any

              ////////////////////////////////////////////////////////////

              const usermail =  await auth('post','auth-mail',{
                mail:'marfenox@gmail.com' 
               })
              .catch(() => {
                console.log("correo Incorrecto");
              }) as any
              
             /////////////////////////////////////////////////////////////////////
             //Verificacion deexistencia  de usuario o E-mail
             
          //  const veriNom = usuarios.find((usuario:any) => usuario.nombre === valorNombre);
           // const veriEmail = usermail.find((usuario:any) => usuario.correo === valorEmail);
           /*
            if(veriNom?.nombre){
              validation.updateState("nombre",false)
              nombrevalidacion.innerText="El usuario ya existe"
              nombreval.classList.remove('is-valid')
            }
            
            if(veriEmail?.correo){
              validation.updateState("email", false)
              emailvalidacion.innerText="El E-mail ya existe"
              emailval.classList.remove('is-valid')
            }
              */
            if(nombreval.value.length===0 && passval.value.length===0 && emailval.value.length===0 ){
              nombrevalidacion.innerText="Campo usuario esta vacio!"
              nombrevalidacion.style.display="block"
              nombrevalidacion.style.color="#fff"
              nombrevalidacion.style.backgroundColor="rgb(194, 0, 0)"
              passvalidacion.innerText="Campo contraseña esta vacio!"
              passvalidacion.style.display="block"
              passvalidacion.style.color="#fff"
              passvalidacion.style.backgroundColor="rgb(194, 0, 0)"
              emailvalidacion.innerText="Campo E-mail esta vacio!"
              emailvalidacion.style.display="block"
              emailvalidacion.style.color="#fff"
              emailvalidacion.style.backgroundColor="rgb(194, 0, 0)"
            }
      
            else if(nombreval.value.length===0){
              nombrevalidacion.innerText="Campo usuario esta vacio!"
            }
            else if(emailval.value.length===0){
              emailvalidacion.innerText="Campo E-mail esta vacio!"
            }
            else if(passval.value.length===0){
              passvalidacion.innerText="Campo contraseña esta vacio!"
            }
          
            function generarJWT(usuario: any): string {
              // Aquí generamos el token JWT utilizando el método sign de la biblioteca jsonwebtoken
              let token = jwt.sign({ userId: usuario.id }, 'secreto', { expiresIn: '1m' });
              return token;
            }
///////////////////////////////////////////////////////////////////////////////////
           if (validation.isValid()) {//Paso 6
           nombrevalidacion.style.display='none'
           passvalidacion.style.display='none'
           emailvalidacion.style.display='none'
           nombreval.classList.remove('is-valid')
           passval.classList.remove('is-valid')
           emailval.classList.remove('is-valid')
/////////////////////////////////////////////////////////
         const newToken = generarJWT(valorNombre+valorEmail+passValor)
      
         /*
          await request('post','login/registrar',{
          nombre: valorNombre,
          correo: valorEmail,
          contrasena: passValor,
          token: newToken
          },newToken).then(response=>{
          alert('Su reguistro fue exitoso.')
          navigate('/login')

          }).catch( error =>{
          console.error("Error en el sistema de reguistro"+ error)
          })
  */   
}
        
    
            }) 
        }
        export default regForm;