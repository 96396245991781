import '/src/vendor/css/bootstrap.min.css';
import './style-login.css';
import '/src/resouces/css/style.css';
import { renderPage, navigate ,request } from '/src/resouces/js/commonRequest.js';
import { Buffer } from 'buffer-es6';
import { auth } from '/src/resouces/js/requestMain.js';
global.Buffer = Buffer;


const recovery = async()=>{

document.title = window.location.pathname.split("/")[2]

function popstateHandler() {
    if (!confirm("¿Estás seguro que deseas salir?")) {
        navigate('/password/recover'); // Cancelar el retroceso en el historial si el usuario cancela la acción
        window.removeEventListener('popstate', popstateHandler);
      } else {
        //login();
        navigate('/login');// antiguo login()
        window.location.reload();
        //{loadPage};
         // Remover el listener del evento popstate
    }
}

window.addEventListener('popstate', popstateHandler);

await renderPage('/pages/recovery.hbs', {container: {undefined} }) 



   //Cerrar recovery
   document.getElementById('cancelarRecupera')?.addEventListener('click',()=>{
    if (!confirm("¿Estás seguro que deseas salir?")) {
      navigate('/password/recover'); // Cancelar el retroceso en el historial si el usuario cancela la acción
    } else {
      //login();
      navigate('/login');// antiguo login()
      window.location.reload();
     // {loadPage};
       // Remover el listener del evento popstate
  }
  })

interface ValidationState {
    [key: string]: boolean;
}

interface Validation {
    state: ValidationState;
    subscribers: ((state: ValidationState) => void)[];
    updateState: (id: string, stateValid: boolean) => void;
    subscribe: (callbackFunction: (state: ValidationState) => void) => void;
    isValid: () => boolean;
}

const validation: Validation = {
    state: {},
    subscribers: [],

    updateState: function (id: string, stateValid: boolean) {
        this.state[id] = stateValid;
        this.subscribers.forEach((subscriber) => {
            subscriber(this.state);
        });
    },

    subscribe: function (callbackFunction: (state: ValidationState) => void) {
        this.subscribers.push(callbackFunction);
    },

    isValid: function () {
        for (const id in this.state) {
            if (!this.state[id]) {
                return false;
            }
        }
        return true;
    }
};

//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
const nombrevalidacion = document.getElementById('nombrevalidacion') as HTMLElement
const emailvalidacion = document.getElementById('emailvalidacion') as HTMLElement
const formulariovalit=document.getElementById('formulariovalidacion')
const nombreval = document.getElementById('nombre') as HTMLInputElement 

//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
validation.subscribe((state: ValidationState) => {
    if(state.nombre===true){
     nombrevalidacion.style.display="block"
     nombrevalidacion.style.color="#fff"
    }
    else{
     nombrevalidacion.style.color="#fff"
     nombrevalidacion.style.backgroundColor="rgb(194, 0, 0)"
    }
 
    if(state.email===true){
    emailvalidacion.style.display="block"
    emailvalidacion.style.color="#fff"
    }
    else{
    emailvalidacion.style.color="#fff"
    emailvalidacion.style.backgroundColor="rgb(194, 0, 0)"
    }
 });


 formulariovalit?.addEventListener('submit', async (e) => {
    e.preventDefault();
  
    // Acceder a los elementos del formulario
    const nombreInput = document.getElementById('nombre') as HTMLInputElement;

  
    // Obtener los valores del formulario
    const confirNom = nombreInput.value;

      // Lógica para la validación de campos vacíos
      //const nombreValidacion = document.getElementById('nombrevalidacion') as HTMLElement;

    try {
      // Esperar a que objetoUsuario esté completamente cargado
      const usuarios =  await auth('post','auth-post',{
        nombre:confirNom
       })
      .catch(() => {
        console.log("Usuario Incorrecto");
      }) as any
  
      const usermail =  await auth('post','auth-mail',{
        mail:'marfenox@gmail.com' 
       })
      .catch(() => {
        console.log("correo Incorrecto");
      }) as any
      // Buscar el usuario en objetoUsuario
      const usuarioOk = usuarios.find((usuario:any) => usuario.nombre === confirNom);
      // Buscar el usuario en correo
      const correoOk = usermail.find((usuario:any) => usuario.correo === confirNom);
      // Validar el usuario y la contraseña
      ///Aqui verificamos el usuario corecto
      if (usuarioOk || correoOk) {
        const usuarioEnviar = {
          nombre:usuarioOk?.nombre || correoOk?.nombre,
          correo:usuarioOk?.correo || correoOk?.correo,
        }

        validation.updateState("correo", true);
        nombreInput.classList.add('is-valid');
        nombrevalidacion.innerText = "Usuario validado!";
        nombrevalidacion.style.display = "block";
        nombrevalidacion.style.backgroundColor="darkgreen";
       ///////////////////////////////////// Loading send
       const sendLoading = document.createElement('div') 
       sendLoading.id="sendLoading"
       const loadingGif = document.createElement('img')
       loadingGif.style.position="fixed"
       loadingGif.style.top="45%"
       loadingGif.style.left="37%"
       loadingGif.style.animation='rotate 1s linear infinite';
       loadingGif.src='/resouces/img/load.svg';
       document.getElementById('footer-form')?.appendChild(sendLoading)
       document.getElementById('sendLoading')?.appendChild(loadingGif)
       const textSend = document.createElement('span') 
       textSend.textContent="Enviando..."
       textSend.style.fontWeight="bold"
       document.getElementById('sendLoading')?.appendChild(textSend)
       const mensajeEnvion = document.querySelector('input[type="submit"]') as HTMLInputElement;
       mensajeEnvion.value="Esperar mandando al E-mail..."

//////////////////////////////////////////////////////////////////
       const locationhref=window.location.href.split('/')[0]+window.location.href.split('/')[1]+window.location.href.split('/')[2]
       const token = await auth('post','mail-post',{
        correo:usuarioEnviar.correo,
        nombre:usuarioEnviar.nombre
       }) .catch(() => {
        console.log("Datos de envio incorrectos");
      }) as any
       
        await request('post',`api/auth/register`,{
           "Correo" :`${usuarioEnviar.correo}`,
           "Nombre":`${usuarioEnviar.nombre}`,
           "url":`http://${locationhref}/change/${token.token}`
        },'').then(() => {
          loadingGif.style.display = 'none';
          const mensajeEnvion = document.querySelector('input[type="submit"]') as HTMLInputElement;
          mensajeEnvion.value="Todo Ok"
          setTimeout(()=>{
            alert('Envio fue exitoso revisa la bandeja de entrada de tu correo. Tambien verificar en spam')
            navigate('/login');
           },500)
          // Ocultar el GIF de carga cuando se completa la solicitud
         
          // Aquí puedes manejar la respuesta de la solicitud
      }).catch(error => {
          // Ocultar el GIF de carga en caso de error
          loadingGif.style.display = 'none';
          // Aquí puedes manejar el error
      });  
      
 
         
      
      } else {
        validation.updateState("nombre", false);
        const nombreValidacion = document.getElementById('nombrevalidacion') as HTMLElement;
        nombreValidacion.style.display = "block";
        nombreValidacion.innerText = "Usuario incorrecto!";
        nombreInput.classList.remove('is-valid');
      }
  
      if (nombreInput.value.length === 0) {
        nombrevalidacion.innerText = "Campo usuario está vacío!";
        nombrevalidacion.style.display = "block";
      } else if (nombreInput.value.length === 0) {
        nombrevalidacion.innerText = "Campo usuario está vacío!";
      } 
  
    } catch (error) {
      console.error('Error al validar usuario:', error);
      // Manejar el error de alguna manera
    }
  });
  
 
}

export default recovery