// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {margin-bottom: 20px; margin-top: 0;}
#progress{
    max-width: 290px; 
    overflow: hidden; 
    text-align: center;
    color:#fff;
    font-size:1.1em;
    font-weight:bold;
    font-family: 'Mulish', sans-serif;
}

`, "",{"version":3,"sources":["webpack://./src/login/style-login.css"],"names":[],"mappings":"AAAA,IAAI,mBAAmB,EAAE,aAAa,CAAC;AACvC;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,iCAAiC;AACrC","sourcesContent":["hr {margin-bottom: 20px; margin-top: 0;}\r\n#progress{\r\n    max-width: 290px; \r\n    overflow: hidden; \r\n    text-align: center;\r\n    color:#fff;\r\n    font-size:1.1em;\r\n    font-weight:bold;\r\n    font-family: 'Mulish', sans-serif;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
