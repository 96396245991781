import regForm from '/src/login/Reg.tsx'
import login from '/src/login/Login.tsx';
import recovery from '/src/login/recovery.tsx';

export const routes = {
    '/login':login,
    '/registro': regForm,
    '/password/recover':recovery
   

};
